<template>
  <div class="container">
      <header>
          华西集团-易方汇智对账系统
          <div class="loginOut" @click="loginOut">
              退出登录
              <i class="el-icon-switch-button"></i>
          </div>
      </header>
      <main>
          <div class="main_1">
              <div class="main_1_title">
                  <div>本月账单</div>
                  <div @click="$router.push('/HxCurrent')">详情</div>
              </div>
              <div class="main_1_content">
                  <div class="item">
                    <div class="item-text">本月服务费用</div>
                    <div class="item-money">{{this.dataInfo.thisMonthLeaseReceivables}}<span>元</span></div>
                    <div class="item-circle" style="color:#ec2d4d">{{this.dataInfo.deviceTotal}}</div>
                    <div class="item-title">设备总数</div>
                </div>
                <div class="item">
                    <div class="item-text">本年度结余费用</div>
                    <div class="item-money" style="color:#000">{{this.dataInfo.thisYearDepositReceivables}}<span>元</span></div>
                    <div class="item-circle" style="border-color:#f59a23;color:#000">{{this.dataInfo.towerCount}}</div>
                    <div class="item-title">塔机数</div>
                </div>
                <div class="item">
                    <div class="item-text">上年度结余费用</div>
                    <div class="item-money">{{this.dataInfo.lastYearDepositReceivables}}<span>元</span></div>
                    <div class="item-circle" style="border-color:#ec2d4d;color:#000">{{this.dataInfo.lifterCount}}</div>
                    <div class="item-title">升降机数</div>
                </div>
              </div>
          </div>
          <div class="main_1">
              <div class="main_1_title">
                  <div>历史账单</div>
                  <div @click="$router.push('/HxHistory')">详情</div>
              </div>
              <div class="main_1_content line">99
              </div>
          </div>
      </main>
      <footer>
          <div class="left">
              <div class="left-top">
                  <div :class="{tabs:true,action:tabs==1}" @click="tabsChange(1)">塔机数量</div>
                  <div :class="{tabs:true,action:tabs==2}" @click="tabsChange(2)">升降机数量</div>
              </div>
              <div class="left-content bar"></div>
          </div>
          <div class="right">
              <div class="title">项目台数排名</div>
              <div class="list">
                  <div class="item" v-for="(item,i) in projectList" :key="i">
                      <div class="index" :style="{color:i+1<4?'#fff':'',background:i+1<4?'#314659':''}">{{i+1}}</div>
                      <div class="name">{{item.name}}</div>
                      <div class="count">{{item.count}}</div>
                  </div>
              </div>
          </div>
      </footer>
  </div>
</template>

<script>
import {huaxi_line,huaxi_bar}from "../../assets/js/echarts"
export default {
    data(){
        return{
            tabs:1,
            dataInfo:{
                deviceTotal: 0,
                lastYearDepositReceivables: 0,
                lifterCount: 0,
                projectTop: {},
                thisMonthLeaseReceivables: 0,
                thisYearDepositReceivables: 0,
                towerCount: 0,
            },
            projectList:[],
            towerOption:{},
            lifterrOption:{},
        }
    },
    methods:{
        // 获取基础信息
        indexThisMonth(){
            this.$api.indexThisMonth().then(res=>{
                let tempList = [];
                if(res.code != 200) return;
                res.data.lastYearDepositReceivables = res.data.lastYearDepositReceivables / 100;
                res.data.thisMonthLeaseReceivables = res.data.thisMonthLeaseReceivables / 100;
                res.data.thisYearDepositReceivables = res.data.thisYearDepositReceivables / 100;
                for (const key in res.data.projectTop) {
                    if(tempList.length >= 5) break;
                    tempList.push({name:key,count:res.data.projectTop[key]})
                }
                this.projectList = tempList;
                this.dataInfo = res.data;
            })
        },
        // 获取直线图和柱图数据
        indexHistory(){
            this.$api.indexHistory().then(res=>{
                if(res.code != 200) return;
                for (let i = 0; i < 12; i++) {
                    res.data.leaseReceivablesList[i] = res.data.leaseReceivablesList[i] / 100
                    res.data.thisYearDepositReceivablesList[i] = res.data.thisYearDepositReceivablesList[i] / 100
                }
                let lineO = {
                    doc:'.line',
                    dateList: res.data.dateList,
                    leaseReceivablesList: res.data.leaseReceivablesList,
                    thisYearDepositReceivablesList: res.data.thisYearDepositReceivablesList
                }
                this.towerOption = {
                    doc:'.bar',
                    dateList: res.data.dateList,
                    dataList:res.data.towerCountList
                }
                this.lifterrOption = {
                    doc:'.bar',
                    dateList: res.data.dateList,
                    dataList:res.data.lifterCountList
                }
                huaxi_line(lineO);
                huaxi_bar(this.towerOption);
            })
        },
        // tabs切换
        tabsChange(index){
            this.tabs = index;
            if(index == 1){
                huaxi_bar(this.towerOption);
            }else{
                huaxi_bar(this.lifterrOption);
            }
        },
        loginOut(){
            this.$router.push('/HxLogin')
        }
    },
    mounted(){
        this.indexThisMonth();
        this.indexHistory();
    }
}
</script>

<style lang="scss" scoped>
.container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(240, 242, 245, 1);
    overflow: hidden;
}
header{
    background: #fff;
    height: 65px;
    font-weight: bold;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .loginOut{
        position: absolute;
        right: 2%;
        color: #169bd5;
        font-size: 17px;
        cursor: pointer;
        &:hover{color: rgba($color: #169bd5, $alpha: 0.8);}
        i{font-weight: bold;}
    }
}
main{
    flex: 1;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    .main_1{
        background: #fff;
        width: 49.7%;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .main_1_title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            height: 12%;
            border-bottom: 1px solid rgba(233, 233, 233, 1);
            div:nth-child(1){
                font-weight: bold;
            }
            div:nth-child(2){
                font-weight: bold;
                color: #169bd5;
                font-size: 15px;
                cursor: pointer;
            }
        }
        .main_1_content{
            flex: 1;
            display: flex;
            justify-content: center;
            .item{
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .item-text{
                    color: #666;
                    font-size: 17px;
                }
                .item-money{
                    color: #ec2d4d;
                    font-size: 32px;
                    font-weight: 400;
                    span{
                        color: #666;
                        font-size: 14px;
                    }
                }
                .item-circle{
                    border: 4px solid #02a7f0;
                    width: 180px;
                    height: 180px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-size: 50px;
                    border-radius: 50%;
                    margin: 10% 0;
                }
                .item-title{
                    font-size: 28px;
                    font-weight: 400;
                }
            }
        }
    }
}
footer{
    height: 408px;
    margin: 10px;
    margin-top: 0;
    background: #fff;
    border-radius: 5px;
    display: flex;
    .left{
        flex: 1;
        display: flex;
        flex-direction: column;
        .left-top{
            height: 12%;
            border-bottom: 1px solid rgba(233, 233, 233, 1);
            display: flex;
            padding-left: 1%;
            .tabs{
                height: 100%;
                width: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .action{
                color: #3ba1ff;
                border-bottom: 2px solid #3ba1ff;
            }
        }
        .left-content{
            flex: 1;
        }
    }
    .right{
        width: 500px;
        display: flex;
        flex-direction: column;
        .title{
            font-weight: bold;
            font-size: 18px;
             height: 50px;
            display: flex;
            align-items: center;
        }
        .list{
            flex: 1;
            .item{
                display: flex;
                align-items: center;
                height: 60px;
                padding: 0 5px;
                font-size: 15px;
                .index{
                    width: 30px;
                    height: 25px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(240, 242, 245, 1);
                }
                .name{
                    height: 30px;
                    line-height: 30px;
                    margin-left: 15px;
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .count{
                    width: 100px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>